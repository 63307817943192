import {
    FLOORPLAN_QUERY,
    FLOORPLAN_UPDATE,
    FLOORPLAN_UPDATE_FILTERS,
    FLOORPLAN_UPDATE_ACTIVE,
    FLOORPLAN_UPDATE_ACTIVE_FROM_SLUG,
    FLOORPLAN_UPDATE_FILTER_ACTIVE,
    FLOORPLAN_UPDATE_FILTER_VALUE,
    FLOORPLAN_TOGGLE_FILTER_DROPDOWN,
    FLOORPLAN_FILTER_FLOORPLANS,
    FLOORPLAN_UPDATE_PRODUCT_FILTER_ID,
    FLOORPLAN_UPDATE_PRODUCTS,
    FLOORPLAN_UPDATE_ACTIVE_ELEV,
    FLOORPLAN_ACTIVE_TO_COMPARE,
    FLOORPLAN_COMPARE_TO_ACTIVE,
    FLOORPLAN_UPDATE_ACTIVE_PLAN,
    FLOORPLAN_UPDATE_DISPLAYED_PLAN,
    FLOORPLAN_UPDATE_SEARCH_FILTER_ID,
    FLOORPLAN_FILTER_COMPARE_FLOORPLANS,
    FLOORPLAN_UPDATE_ACTIVE_COMPARE_FLOORPLAN,
    FLOORPLAN_SETUP_COMPARE_FILTERS,
    FLOORPLAN_UPDATE_COMPARE_FILTER_VALUE,
    FLOORPLAN_UPDATE_SITEPLAN_ACTIVE_FLOORPLAN,
    FLOORPLAN_CLEAR_SEARCH_FILTER,
    FLOORPLAN_CLEAR_NORMAL_FILTERS,
    FLOORPLAN_SEND_EMAIL,
    FLOORPLAN_UPDATE_EMAIL_ERROR,
    FLOORPLAN_EMAIL_STATUS,
    FLOORPLAN_CLEAR_COMPARE,
    FLOORPLAN_CLEAR_RIGHT_COMPARE,
    FLOORPLAN_CLOSE_ALL_FILTER_DROPDOWNS,
    FLOORPLAN_UPDATE_COMPARE_DISPLAYED_PLAN,
    FLOORPLAN_EMAIL_CLEAR_SUBMISSION,
    FLOORPLAN_SETUP_SITEPLAN_LIST,
    FLOORPLAN_CLEAR_COMPARE_HIDDEN
} from '../actions/actionTypes';

function filterUpdateValueByType(value, filter, index) {
    switch(filter.type) {
        case "product":
            filter.index = index;
            return filter.values[value];
        default:
            filter.index = index;
            return value;
    }
}

function filterFieldValueByName(floorplan, elevation, name) {
    var eKeys = Object.keys(floorplan.elevation);

    switch(name) {
        case "Bedrooms":
            return floorplan.elevation[eKeys[elevation]].info.bedrooms;
        case "SQ.FT.":
            return {
                first: parseInt(floorplan.elevation[eKeys[elevation]].info.square_feet)
            }
        case "Block":
            return floorplan.units[0].block.ID;
        case "product":
            return floorplan.product.term_id;
    }

    return -1;
}

//filters a floorplan based on the filter that was passed in. Returns true if it fails
function filterFloorplan(floorplan, elevation, filter) {
    switch(filter.type) {
        case "number":
            return filterNumberCmp(filterFieldValueByName(floorplan, elevation, filter.name), filter.value);
        case "id":
        case "product":
            return filterMatchCmp(filterFieldValueByName(floorplan, elevation, filter.name), filter.value);
        default:
            return true;
    }
}

function filterNumberCmp(a, b) {
    //we need to check both numbers
    var first = parseInt(a.first);
    var second = parseInt(a.second);

    if(!isNaN(second)) {
        if(second < b) return true;
        return false;
    }

    if(first < b) return true;
    return false;
}

function filterMatchCmp(a, b) {
    if(a === b) return false;
    return true;
}

function filterSearchCmp(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();

    return !a.includes(b);
}

function findFloorplanByWPID(floorplans, ID) {
    for(var i = 0; i < floorplans.length; i++) {
        if(floorplans[i].ID == ID) return floorplans[i];
    }

    return null;
}

function findIndexOfFloorplanByWPID(floorplans, ID) {
    for(var i = 0; i < floorplans.length; i++) {
        if(floorplans[i].ID == ID) return i;
    }

    return null;
}

function floorplanHasUnits(floorplan) {
    var units = false;
    for(var i = 0; i < floorplan.units.length; i++) {
        if(floorplan.units[i].avaliblity && floorplan.units[i].block.active)
            units = true;
    }

    //we need to do a check for the block now
    //we need to see if the

    return units;
}

function floorplanElevationExistsOnActiveBlock(floorplan) {
    var onActiveBlock = false;

    for(var i = 0; i < floorplan.units.length; i++) {
        var eKeys = Object.keys(floorplan.elevation);
        if(
            floorplan.elevation[eKeys[floorplan.ele]].label_elevation.value ===
            floorplan.units[i].unit_elevation.value
        ) {
            if(floorplan.units[i].block.active)
                onActiveBlock = true;
        }
    }

    return onActiveBlock;
}

function findIndexOfFloorplanByEleID(floorplans, ID, ele) {
    for(var i = 0; i < floorplans.length; i++) {
        if(floorplans[i].ID == ID) {
            var eKeys = Object.keys(floorplans[i].elevation);
            var index = eKeys.indexOf(ele);
            
            if(index === floorplans[i].ele)
                return i;
        }
    }
    return -1;
}

function findFloorplanBySlug(floorplans, slug) {
    for(var i = 0; i < floorplans.length; i++) {
        const floorplan = floorplans[i];
        const name = floorplan.post_name;

        const eKeys = Object.keys(floorplans[i].elevation);
        for(var j = 0; j < eKeys.length; j++) {
            const ele = eKeys[j];
            const letter = floorplan.elevation[ele].label_elevation.letter_label
                .toLowerCase().replace(new RegExp(" ", "g"), "-");

            const fslug = `${name}-${letter}`;

            //set the ele on the floorplan to this current ele and then return it
            if(fslug === slug) {
                floorplan.ele = j;
                return floorplan;
            }
        }
    }

    //if we didn't find one we should just return ... nothing ? or an empty object or the first one
    //for now ill just do the first one
    return floorplans[0];
}

const initialState = {
    filters: [],
    floorplans: [],
    activeFloorplan: {},
    displayFloorplans: [],
    siteplanFloorplans: [],
    compareFloorplans: {}, 
    compareFilters: {},
    activeCompareFloorplans: {},
    products: [],
    productFilterID: -1,
    searchFilterID: -1,
    emailError: false,
    emailSubmitted: false,
    emailSubmitting: false
};

function floorplan(state = initialState, action) {
    switch(action.type) {
        case FLOORPLAN_QUERY:
            return state;
        case FLOORPLAN_UPDATE:
            console.log(action.floorplans);
            return Object.assign({}, state, {
                floorplans: action.floorplans
            });
        case FLOORPLAN_UPDATE_ACTIVE:
            return Object.assign({}, state, {
                activeFloorplan: state.displayFloorplans[action.key]
            });
        case FLOORPLAN_UPDATE_ACTIVE_FROM_SLUG:
            //we need to check all of the floorplans here
            var floorplan = findFloorplanBySlug(state.displayFloorplans, action.slug);

            return Object.assign({}, state, {
                activeFloorplan: floorplan
            });
        case FLOORPLAN_UPDATE_SITEPLAN_ACTIVE_FLOORPLAN: 
            var plans = [].concat(state.siteplanFloorplans);
            var index = findIndexOfFloorplanByEleID(
                plans,
                action.ID,
                action.ele
            );

            var floorplan = plans[index];
            floorplan.bID = action.bID;
            floorplan.unit = action.unit;
            
            return Object.assign({}, state, {
                activeFloorplan: floorplan
            });
        case FLOORPLAN_ACTIVE_TO_COMPARE:
            var filters = state.filters;
            var productFilterID = state.productFilterID;
            var compareFilters = state.compareFilters;
            var compareFloorplans = state.activeCompareFloorplans;

            compareFilters['left'] = state.filters[productFilterID].value;
            compareFloorplans['left'] = action.floorplan;

            return Object.assign({}, state, {
                compareFilters: compareFilters,
                activeCompareFloorplans: compareFloorplans
            });
        case FLOORPLAN_COMPARE_TO_ACTIVE:
            //gotta update some stuff
            var filters = state.filters;
            var productFilterID = state.productFilterID;
            var compareFilters = state.compareFilters;
            var compareFloorplans = state.activeCompareFloorplans;
            
            var floorplan = {};
            floorplan[action.side] = compareFloorplans[action.side];
            filters[productFilterID].value = compareFilters[action.side];

            return Object.assign({}, state, {
                activeFloorplan: action.floorplan,
                activeCompareFloorplans: {},
                filters: filters
            });
        case FLOORPLAN_CLEAR_COMPARE:
            return Object.assign({}, state, {
                activeCompareFloorplans: {}
            });
        case FLOORPLAN_CLEAR_COMPARE_HIDDEN:
            var compare = Object.assign({}, state.compareFloorplans);

            var ss = 'left';
            if(action.side === 'left') ss = 'right';

            //clear all of the hidden fields for this side
            for(var i = 0; i < compare[ss].length; i++) {
                compare[ss][i].compareHide = false;
            }

            return Object.assign({}, state, {
                compareFloorplans: compare
            });
        case FLOORPLAN_CLEAR_RIGHT_COMPARE:
            Object.assign({}, state.activeCompareFloorplans);
            
            return Object.assign({}, state, {
                activeCompareFloorplans: {
                    left: state.activeCompareFloorplans['left']
                }
            })
        case FLOORPLAN_UPDATE_ACTIVE_COMPARE_FLOORPLAN:
            var act = Object.assign({}, state.activeCompareFloorplans);
            var compare = Object.assign({}, state.compareFloorplans);

            act[action.side] = compare[action.side][action.key];
            act[action.side].disp = 0;

            var ss = 'left';
            if(action.side === 'left') {
                ss = 'right';
            }  
            
            //get the ele for this function
            var eKeys = Object.keys(act[action.side].elevation);

            //find this one in the other list and make it hidden
            var index = findIndexOfFloorplanByEleID(
                compare[ss],
                act[action.side].ID,
                eKeys[act[action.side].ele]
            );
            
            if(index !== -1)
                compare[ss][index].compareHide = true;
            
            return Object.assign({}, state, {
                activeCompareFloorplans: act,
                compareFloorplans: compare
            });
        case FLOORPLAN_UPDATE_COMPARE_DISPLAYED_PLAN:
            //this needs to get updated in hte normal active floorplan
            //list and it also needs to get updates in the compare floorplan
            //list i hate the way that i set this up now

            var active = Object.assign({}, state.activeCompareFloorplans);
            active[action.side].disp = action.plan;

            var displayPlans = [].concat(state.displayFloorplans);
            var comparePlans = Object.assign({}, state.compareFloorplans);

            //find the floorplan index in all of those lists
            var sKeys = Object.keys(active[action.side].elevation);
            var index = findIndexOfFloorplanByEleID(
                displayPlans,
                active[action.side].ID,
                sKeys[active[action.side].ele]
            );

            if(index !== -1)
                displayPlans[index].disp = action.plan;
            
            var keys = Object.keys(comparePlans);
            for(var i = 0; i < keys.length; i++) {
                sKeys = Object.keys(active[action.side].elevation);
                index = findIndexOfFloorplanByEleID(
                    comparePlans[keys[i]],
                    active[action.side].ID,
                    sKeys[active[action.side].ele]
                );
                
                if(index !== -1)
                comparePlans[keys[i]][index].disp = action.plan;
            }

            return Object.assign({}, state, {
                activeCompareFloorplans: active,
                displayFloorplans: displayPlans,
                compareFloorplans: comparePlans
            });
        case FLOORPLAN_UPDATE_DISPLAYED_PLAN:
            var display = [].concat(state.displayFloorplans);
            display[action.floorplanID].disp = action.plan;

            return Object.assign({}, state, {
                displayFloorplans: display
            });
        case FLOORPLAN_UPDATE_ACTIVE_PLAN:
            var floorplan = Object.assign({}, state.activeFloorplan);
            var display = [].concat(state.displayFloorplans);
            
            floorplan.disp = action.plan;
            
            if(typeof display[floorplan.dispID] !== 'undefined')
            display[floorplan.dispID].disp = action.plan;

            return Object.assign({}, state, {
                activeFloorplan: floorplan,
                displayFloorplans: display
            });
        case FLOORPLAN_UPDATE_ACTIVE_ELEV:
            var floorplan = state.activeFloorplan;
            floorplan.ele = action.ele;

            return Object.assign({}, state, {
                activeFloorplan: floorplan
            });
        case FLOORPLAN_UPDATE_FILTERS:
            //first we need to get the list values
            return Object.assign({}, state, {
                filters: action.newFilters
            });
        case FLOORPLAN_SETUP_COMPARE_FILTERS:
            var compareFilters = state.compareFilters;
            var filters = state.filters;
            var productFilterID = state.productFilterID;
            compareFilters['left'] = compareFilters['right'] = filters[productFilterID].value;
            
            return Object.assign({}, state, {
                compareFilters: compareFilters
            });
        case FLOORPLAN_FILTER_FLOORPLANS:
            // do the filtering in here because the action does not
            // have access to the state and shouldn't /
            // something about anti patterns

            var floorplans = state.floorplans;
            var filters = state.filters;
            var activeList = [];

            //get the search filter first
            for(var i = 0; i < floorplans.length; i++) {
            //we need to check each filter I'm now seeing that this is only half way down now ugh
            
            var eKeys = Object.keys(floorplans[i].elevation);
            for(var k = 0; k < eKeys.length; k++) {
                //we actually need to check the units twice. once for each elevation and we should only
                //compare units that match the elevation we are doing right now. WE can do this by matching
                //the elevation with the block elevation okay here we go
                var add = true;
                var force = false;

                for(var j = 0; j < floorplans[i].units.length; j++) {
                    //find out which units match the current elevation
                    /*
                    if(floorplans[i].post_title === "Bellflower") {
                        console.log(floorplans[i].elevation[eKeys[k]].label_elevation);
                        console.log(floorplans[i].units[j].unit_elevation);
                        console.log(floorplans[i]);
                        console.log("------------");
                    }
                    */

                    if(
                        floorplans[i].elevation[eKeys[k]].label_elevation.value ==
                        floorplans[i].units[j].unit_elevation.value
                    ) {
                        //we have a match
                        if(floorplans[i].units[j].avaliblity === true) break;
                        else add = false;
                    }

                    if(floorplans[i].post_title == "Elderberry") {
                        if(floorplans[i].elevation[eKeys[k]].label_elevation.label == "Elevation C") {
                            force = true;
                        }
                    }
                }

                var searchFilter = filters[state.searchFilterID];

                if(add === true)
                if(searchFilter.active === false)
                for(var j = 0; j < filters.length; j++) {
                    //this is for each filter do a thing i guess

                    if(filters[j].active === true) {
                        if(filterFloorplan(floorplans[i], k, filters[j])) {
                            add = false;
                        }
                    }
                }

                if(add || force) {
                    var floorplan = JSON.parse(JSON.stringify(floorplans[i]));
                    floorplan.realName = floorplan.post_title;
                    floorplan.plan = i;
                    floorplan.disp = 0;
                    floorplan.dispID = activeList.length;
                    floorplan.ele = k;

                    floorplan.post_title += ` ${
                        floorplan.elevation[eKeys[k]].label_elevation.letter_label
                    }`;

                    if(searchFilter.active === true) 
                    if(filterSearchCmp(floorplan.post_title, searchFilter.value))
                    add = false;

                    if(!floorplanHasUnits(floorplan)) add = false;
                    if(!floorplanElevationExistsOnActiveBlock(floorplan)) add = false;

                    if(add || force) {
                        //remove this later i don't care about this right now
                        activeList.push(floorplan);
                    }
                    
                }
            }}

            //sort the list by sqft
            activeList.sort(function(a, b) {
                var aKeys = Object.keys(a.elevation);
                var bKeys = Object.keys(b.elevation);

                return a.elevation[aKeys[a.ele]].info.square_feet -
                    b.elevation[bKeys[b.ele]].info.square_feet
            });
            
            return Object.assign({}, state, {
                displayFloorplans: activeList
            });
        case FLOORPLAN_FILTER_COMPARE_FLOORPLANS:
            //this is almost a copy of the filter floorplans however
            //it does a few minor things differently. IT should be restructured
            //to use the same logic for both, but i just do not have time for that
            //my hand has been forced.

            var floorplans = state.floorplans;
            var filter = state.compareFilters[action.side];
            var activeList = [];

            for(var i = 0; i < floorplans.length; i++) {

            var eKeys = Object.keys(floorplans[i].elevation);
            for(var k = 0; k < eKeys.length; k++) {
                //when i look at this it works different then i thought actually 
                //i dont even know what this project is doing anymore someone send help

                var add = true;
                var force = false;

                for(var j = 0; j < floorplans[i].units.length; j++) {
                    if(
                        floorplans[i].elevation[eKeys[k]].label_elevation.value ==
                        floorplans[i].units[j].unit_elevation.value
                    ) {
                        if(floorplans[i].units[j].avaliblity === true) break;
                        else add = false;
                    }

                    if(floorplans[i].post_title == "Elderberry") {
                        if(floorplans[i].elevation[eKeys[k]].label_elevation.label == "Elevation C") {
                            force = true;
                        }
                    }
                }

                if(add) {
                    //compare floorplans 
                    if(filterMatchCmp(floorplans[i].product.term_id, filter))
                    add = false;
                }

                if(add || force) {
                    var floorplan = JSON.parse(JSON.stringify(floorplans[i]));
                    floorplan.realName = floorplan.post_title;
                    floorplan.plan = i;
                    floorplan.disp = 0;
                    floorplan.dispID = activeList.length;
                    floorplan.ele = k;

                    floorplan.post_title += ` ${
                        floorplan.elevation[eKeys[k]].label_elevation.letter_label
                    }`;
                    
                    if(
                        floorplanHasUnits(floorplan) &&
                        floorplanElevationExistsOnActiveBlock(floorplan)
                    )
                    
                    if(!floorplanHasUnits(floorplan)) add = false;
                    if(!floorplanElevationExistsOnActiveBlock(floorplan)) add = false;

                    if(add || force)
                    activeList.push(floorplan);
                }
            }}

            var compareFloorplans = Object.assign(state.compareFloorplans);
            //console.log(action.side);
            compareFloorplans[action.side] = activeList;

            return Object.assign({}, state, {
                compareFloorplans: compareFloorplans
            });
        case FLOORPLAN_SETUP_SITEPLAN_LIST:
            var floorplans = state.floorplans;
            var activeList = [];

            for(var i = 0; i < floorplans.length; i++) {
                var eKeys = Object.keys(floorplans[i].elevation);
                for(var k = 0; k < eKeys.length; k++) {
                    var add = true;
                    var force = false;

                    var floorplan = JSON.parse(JSON.stringify(floorplans[i]));
                    floorplan.realName = floorplan.post_title;
                    floorplan.plan = i;
                    floorplan.disp = 0;
                    floorplan.dispID = activeList.length;
                    floorplan.ele = k;

                    floorplan.post_title += ` ${
                        floorplan.elevation[eKeys[k]].label_elevation.letter_label
                    }`;

                    if(!floorplanHasUnits(floorplan)) add = false;

                    if(floorplans[i].post_title == "Elderberry") {
                        if(floorplans[i].elevation[eKeys[k]].label_elevation.label == "Elevation C") {
                            force = true;
                        }
                    }

                    if(add || force)
                    activeList.push(floorplan);
                }
            }

            activeList.sort(function(a, b) {
                var aKeys = Object.keys(a.elevation);
                var bKeys = Object.keys(b.elevation);

                return a.elevation[aKeys[a.ele]].info.square_feet -
                    b.elevation[bKeys[b.ele]].info.square_feet
            });
            
            return Object.assign({}, state, {
                siteplanFloorplans: activeList
            });
        case FLOORPLAN_UPDATE_FILTER_VALUE:
            var filters = [].concat(state.filters);
            filters[action.id].value = filterUpdateValueByType(action.value, filters[action.id], action.index);

            if(filters[action.id].type === "search") {
                if(filters[action.id].value !== "") filters[action.id].active = true;
                else filters[action.id].active = false;
            }

            var compare = Object.assign({}, state.compareFilters); 

            if(filters[action.id].type === "product") {
                //update the compare filters
                compare['left'] = filters[action.id].value;
                compare['right'] = filters[action.id].value;
            }

            return Object.assign({}, state, {
                filters: filters,
                compareFilters: compare
            });
        case FLOORPLAN_TOGGLE_FILTER_DROPDOWN:
            //make the dropdown be open
            var filters = [].concat(state.filters);
            var prod = state.productFilterID;
            var sear = state.searchFilterID;

            filters[action.index].open = action.open;

            for(var i = 0; i < filters.length; i++) {
                if(action.open) 
                if(i !== action.index && i !== prod && i !== sear) {
                    //close this one since it not the one we're opening
                    filters[i].open = false;
                }
            }

            return Object.assign({}, state, {
                filters: filters
            });
        case FLOORPLAN_CLOSE_ALL_FILTER_DROPDOWNS:
            var filters = [].concat(state.filters);
            var prod = state.productFilterID;
            var sear = state.searchFilterID;

            for(var i = 0; i < filters.length; i++) {
                if(i !== prod && i !== sear) {
                    filters[i].open = false;
                }
            }

            return Object.assign({}, state, {
                filters: filters
            });
        case FLOORPLAN_CLEAR_NORMAL_FILTERS:
            //close and clear all of the dropdowns
            var filters = [].concat(state.filters);
            var searchID = state.searchFilterID;
            var productID = state.productFilterID;

            for(var i = 0; i < filters.length; i++) {
                if(i !== searchID && i !== productID) {
                    filters[i].open = false;
                    filters[i].value = -1;
                    filters[i].index = -1;
                }
            }

            return Object.assign({}, state, {
                filters: filters
            });
        case FLOORPLAN_CLEAR_SEARCH_FILTER:
            //we need to clear out the values from the search filter
            var searchID = state.searchFilterID;
            var filters = [].concat(state.filters);

            //clear the search value and set the active to false
            filters[searchID].value = "";
            filters[searchID].active = false;

            return Object.assign({}, state, {
                filters: filters
            });
        case FLOORPLAN_UPDATE_COMPARE_FILTER_VALUE:
            var compare = state.compareFilters;
            compare[action.side] = action.term;

            return Object.assign({}, state, {
                compareFilters: compare
            });
        case FLOORPLAN_UPDATE_FILTER_ACTIVE:
            var filters = state.filters;
            filters[action.id].active = action.active;

            return Object.assign({}, state, {
                filters: filters
            });
        case FLOORPLAN_UPDATE_PRODUCTS:
            return Object.assign({}, state, {
                products: action.products
            })
        case FLOORPLAN_UPDATE_PRODUCT_FILTER_ID:
            var filters = state.filters;
            var id = -1;

            filters.map((filter, key) => {
                if(filter.type === "product") id = key;
            });

            return Object.assign({}, state, {
                productFilterID: id
            });
        case FLOORPLAN_UPDATE_SEARCH_FILTER_ID:
            var filters = state.filters;
            var id = -1;

            filters.map((filter, key) => {
                if(filter.type === "search") id = key;
            });

            return Object.assign({}, state, {
                searchFilterID: id
            });
        case FLOORPLAN_SEND_EMAIL:
            var submitting = Object.assign({}, state.emailSubmitting);
            submitting = true;
            submitted = false;

            return Object.assign({}, state, {
                emailSubmitting: submitting
            });
        case FLOORPLAN_EMAIL_STATUS:
            var submitting = Object.assign({}, state.emailSubmitting);
            var submitted = Object.assign({}, state.emailSubmitted);
            var error = Object.assign({}, state.emailError);

            submitting = false;
            if(action.success) submitted = true;
            else error = true;

            return Object.assign({}, state, {
                emailSubmitting: submitting,
                emailSubmitted: submitted
            });
        case FLOORPLAN_EMAIL_CLEAR_SUBMISSION:
            var submitting = Object.assign({}, state.emailSubmitting);
            var submitted = Object.assign({}, state.emailSubmitted);
            submitted = false;
            submitting = false;

            return Object.assign({}, state, {
                emailSubmitted: submitted,
                emailSubmitting: submitting
            });
        case FLOORPLAN_UPDATE_EMAIL_ERROR:
            var error = Object.assign({}, state.emailError);

            error = action.err;

            return Object.assign({}, state, {
                emailError: error
            });
        default: 
            return state;
    }
}

export default floorplan;